import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
import * as ffeFormatters from '@sb1/ffe-formatters';
import { Table, TableHead, TableBody, TableHeaderCell, TableRow, TableDataCell } from '@sb1/ffe-tables-react';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DSExample = makeShortcode("DSExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Formateringsprinsipper`}</h2>
    <p>{`Det finnes mange formateringsprinsipper som er standardisert i Norge, og som bidrar til å øke lesbarheten. I designsystemet har vi komponenter som hjelper med formateringen, så du som utvikler ikke trenger å bekymre deg for at ting ikke vises riktig.`}</p>
    <p>{`Her ser du et utvalg av ulike formateringer som finnes: `}</p>

    <Table mdxType="Table">
  <TableHead mdxType="TableHead">
      <TableRow mdxType="TableRow">
        <TableHeaderCell scope="col" mdxType="TableHeaderCell">Type</TableHeaderCell>
        <TableHeaderCell scope="col" mdxType="TableHeaderCell">Eksempel</TableHeaderCell>
      </TableRow>
  </TableHead>
  <TableBody mdxType="TableBody">
      <TableRow mdxType="TableRow">
        <TableDataCell mdxType="TableDataCell">Datoer</TableDataCell>
        <TableDataCell mdxType="TableDataCell">{ffeFormatters.formatDate(Date.now())}</TableDataCell>
      </TableRow>
      <TableRow mdxType="TableRow">
        <TableDataCell mdxType="TableDataCell">Tall</TableDataCell>
        <TableDataCell mdxType="TableDataCell">{ffeFormatters.formatNumber(100000, {
              locale: 'nb'
            })}</TableDataCell>
      </TableRow>
      <TableRow mdxType="TableRow">
        <TableDataCell mdxType="TableDataCell">Valuta</TableDataCell>
        <TableDataCell mdxType="TableDataCell">{ffeFormatters.formatCurrency(1000, {
              locale: 'nb'
            })}</TableDataCell>
      </TableRow>
      <TableRow mdxType="TableRow">
        <TableDataCell mdxType="TableDataCell">Prosenter</TableDataCell>
        <TableDataCell mdxType="TableDataCell">{ffeFormatters.formatPercentage(7)}</TableDataCell>
      </TableRow>
      <TableRow mdxType="TableRow">
        <TableDataCell mdxType="TableDataCell">Kontonummer</TableDataCell>
        <TableDataCell mdxType="TableDataCell">{ffeFormatters.formatAccountNumber('12345678901')}</TableDataCell>
      </TableRow>
      <TableRow mdxType="TableRow">
        <TableDataCell mdxType="TableDataCell">Fødselsnummer</TableDataCell>
        <TableDataCell mdxType="TableDataCell">{ffeFormatters.formatFodselsnummer('12345678901')}</TableDataCell>
      </TableRow>
      <TableRow mdxType="TableRow">
        <TableDataCell mdxType="TableDataCell">Avstander</TableDataCell>
        <TableDataCell mdxType="TableDataCell">{ffeFormatters.formatDistance(50)}</TableDataCell>
      </TableRow>
  </TableBody>
    </Table>
    <h2>{`formatNumber`}</h2>
    <p>{`Om ingen av de spesialiserte formateringsfunksjonene passer kan du bruke `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`formatNumber`}</code>{`. Denne funksjonen utgjør basen i mange av de andre funksjonene.`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`import formatNumber from '@sb1/ffe-formatters/lib/formatNumber';`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`{
  decimals: 2, // default 0
  thousandSeparator: ',', // default ' ' (space)
  decimalMark: '.', // default ','
}`}</code></pre></div>
    <DSExample name="formatting_Numbers" mdxType="DSExample" />
    <h2>{`formatCurrency`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`import formatCurrency from '@sb1/ffe-formatters/lib/formatCurrency';`}</code></p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`formatCurrency`}</code>{`-komponenten kan gis et objekt som andre argument for å styre `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`prefix`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`postfix`}</code>{`: `}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`{
  prefix, // default 'kr. '
  postfix, // default ',-' hvis heltall, '' hvis ikke
}`}</code></pre></div>
    <DSExample name="formatting_Currency" mdxType="DSExample" />
    <h2>{`formatPercentage`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`import formatPercentage from '@sb1/ffe-formatters/lib/formatPercentage';`}</code></p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`formatPercentage`}</code>{`-komponenten gir prosenter det riktige mellomrommet mellom tall og % med et non-breaking space. Runder av til så få desimaler som mulig, med en default cap på to desimaler. Dette kan overstyres i `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`options`}</code>{`-objektet.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`{
    maxDecimals, // default 2
    minDecimals, // default 0
}`}</code></pre></div>
    <DSExample name="formatting_Percentage" mdxType="DSExample" />
    <h2>{`formatFodselsnummer`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`import formatFodselsnummer from '@sb1/ffe-formatters/lib/formatFodselsnummer';`}</code></p>
    <p>{`Deler opp fødselsnummeret i to deler med et non-breaking space, som betyr at selv om det er et mellomrom, så vil ikke fødselsnummeret dele seg over 2 linjer.`}</p>
    <DSExample name="formatting_Birthnumber" mdxType="DSExample" />
    <h2>{`formatAccountNumber`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`import formatAccountNumber from '@sb1/ffe-formatters/lib/formatAccountNumber';`}</code></p>
    <DSExample name="formatting_Accountnumber" mdxType="DSExample" />
    <h2>{`formateDate`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`formateDate`}</code>{`-komponenten støtter bare norsk locale, og formaterer Date-objekter og timestamps. `}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`import formatDate from '@sb1/ffe-formatters/lib/formatDate';`}</code></p>
    <DSExample name="formatting_Date" mdxType="DSExample" />
    <h2>{`formatDistance`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`import formatDistance from '@sb1/ffe-formatters/lib/formatDistance';`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`{
  units, // default 'km',
}`}</code></pre></div>
    <DSExample name="formatting_Distance" mdxType="DSExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      